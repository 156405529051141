import React, { Component } from "react";
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

class Menu extends Component {

  render() {
    return (
      <nav className="c-menu" role="navigation" aria-label="main navigation"> 
        <button
          className={
              "c-menu__toggle " + (this.props.open ? "is-active" : "")
          }
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={this.props.action}
          >
          Menu
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div
          id="menu"
          className={"c-menu__panel " + (this.props.open ? "is-active" : "")}
        >
          {this.props.open}
          <ul className="c-menu__pages">
            <li onClick={this.props.action}><AnchorLink to="/#projects" className="s-projects">Projects</AnchorLink></li>
            <li><Link to="/approach" className="s-approach" onClick={this.props.action}>Approach</Link></li>
            <li><Link to="/contact" className="s-contact" onClick={this.props.action}>Contact</Link></li>
          </ul>
          <ul className="c-menu__social">
            <li><a href="https://www.linkedin.com/company/6461947/admin/" className="u-color-linkedin">LinkedIn</a></li>
            <li><a href="https://www.instagram.com/warehouse_six/" className="u-color-instagram">Instagram</a></li>
            <li><a href="https://twitter.com/warehouse_six" className="u-color-twitter">Twitter</a></li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Menu;


