import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql } from "gatsby"
import { ParallaxProvider } from 'react-scroll-parallax'
import "../assets/stylesheets/app.scss"
import Header from "../components/header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ParallaxProvider>
          <Header
            siteTitle={data.site.siteMetadata.title}
          />          
          <main>
            {children}
          </main>
      </ParallaxProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
