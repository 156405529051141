import PropTypes from "prop-types"
import React, { Component } from "react";
import { Link } from "gatsby"
import Menu from "../components/menu"

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggleMenu() {
    this.setState({open: !this.state.open});
    if(!this.state.open) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
  }

  render() {
    return (
      <header className="c-header">
        <div className="u-wrapper u-flex u-flex--align-center u-flex--justify-content-space-bewteen">
          <Menu action={this.toggleMenu.bind(this)} open={this.state.open} />
          <Link
            to="/"
            className={
              "c-brand " + (this.state.open ? "menu-open" : "")
            }
          >
            {this.props.siteTitle}.
          </Link>
        </div>
     </header>
    )
  }

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
